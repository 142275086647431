<template>
    <BT-Blade-Items
        addBladeName="automatic-line-item-group"
        :bladesData="bladesData"
        bladeName="automatic-line-item-groups"
        canAdd
        canDelete
        :headers="[
            { text: 'Group Name', value: 'groupName', title: 1, searchable: true }]"
        navigation="automatic-line-item-groups"
        title="Automatic Line Groups"
        useServerPagination />
</template>

<script>
export default {
    name: 'Automatic-Line-Item-Groups-Blade',
    props: {
        bladesData: null
    }
}
</script>